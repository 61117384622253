// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/PhotoGallery.css */
.photo-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.thumbnail {
  width: 150px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.2s;
}

.thumbnail:hover {
  transform: scale(1.10);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  background: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.enlarged-photo {
  width: 100%;
  height: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: grey;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PhotoGallery.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,sCAAsC;AACxC;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,qCAAqC;AACvC;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["/* src/PhotoGallery.css */\r\n.photo-gallery {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  gap: 20px;\r\n  justify-content: center;\r\n}\r\n\r\n.thumbnail {\r\n  width: 150px;\r\n  height: 100px;\r\n  object-fit: cover;\r\n  cursor: pointer;\r\n  transition: transform 0.2s;\r\n}\r\n\r\n.thumbnail:hover {\r\n  transform: scale(1.10);\r\n}\r\n\r\n.modal {\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  max-width: 90%;\r\n  max-height: 90%;\r\n  overflow: hidden;\r\n  background: white;\r\n  padding: 10px;\r\n  border-radius: 8px;\r\n  box-shadow: 0 5px 15px rgba(0,0,0,0.3);\r\n}\r\n\r\n.overlay {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  background-color: rgba(0, 0, 0, 0.75);\r\n}\r\n\r\n.enlarged-photo {\r\n  width: 100%;\r\n  height: auto;\r\n}\r\n\r\n.close-button {\r\n  position: absolute;\r\n  top: 10px;\r\n  right: 10px;\r\n  background: grey;\r\n  color: white;\r\n  border: none;\r\n  padding: 5px 10px;\r\n  cursor: pointer;\r\n  border-radius: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
