// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/App.css */
.App {
  text-align: center;
  /* background-color: #152238; */
}

.album {
  margin: 20px;
}

.photos img {
  max-width: 100px;
  margin: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd","sourcesContent":["/* src/App.css */\r\n.App {\r\n  text-align: center;\r\n  /* background-color: #152238; */\r\n}\r\n\r\n.album {\r\n  margin: 20px;\r\n}\r\n\r\n.photos img {\r\n  max-width: 100px;\r\n  margin: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
