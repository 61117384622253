import React, { useState } from 'react';
import Modal from 'react-modal';
import './PhotoGallery.css';

Modal.setAppElement('#root');

const PhotoGallery = ({album}) => {
  
const photos = album.photos;
const title = album.title;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState('');

  const openModal = (photo) => {
    setCurrentPhoto(photo);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentPhoto('');
  };

  return (
    <div>
      <h1 style={{ color: 'black' }}>{title}</h1>
      <div className="photo-gallery">
        {photos.map((photo, index) => (
          <img
            key={`Photo-${index}`}
            src={photo}
            alt={`${index}`}
            className="thumbnail"
            onClick={() => openModal(photo)}
          />
        ))}

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Enlarged Photo"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={closeModal} className="close-button">Close</button>
          <img src={currentPhoto} alt="Enlarged" className="enlarged-photo" />
        </Modal>
      </div>
    </div>
  );
};

export default PhotoGallery;
