import React from "react";
import "../main.css";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.errorInfo) {
      // You can render any custom fallback UI
      return (
        <div className="CenterAlign">
          <br />
          <br />
          <h5>Something appears wrong, please try again later.</h5>
          {/* <br />
            <Link to="/" className="btn btn-primary">Return to home page</Link>
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /> */}
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
